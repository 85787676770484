import yanik from 'assets/images/team/yanik.jpg';
import dudendy from 'assets/images/team/dudendy.webp';
import cj from 'assets/images/team/cj.webp';
import sidlovskyy from 'assets/images/team/sidlovskyy2.png';
import airmangataos from 'assets/images/team/airmangataos.png';
import soloegoist from 'assets/images/team/soloegoist.webp';
import slowlanelife from 'assets/images/team/slowlanelife.webp';
import obivan from 'assets/images/team/obivan.webp';
import sebax11 from 'assets/images/team/sebax11.webp';
import dsi from 'assets/images/team/dsi.webp';
import x9stx6 from 'assets/images/team/9stx6.webp';
import dragonslayer from 'assets/images/team/ds.webp';



import { SocialProps } from './social.data';

export interface Member {
  name: string;
  image: string;
  title: string;
  socials: SocialProps[];
}

export const TEAM_DATA: Member[] = [
  {
    name: 'yanik',
    image: yanik,
    title: 'lord of chaos',
    socials: [
    ]
  },
  {
    name: 'sidlovskyy',
    title: 'fortune seeker',
    image: sidlovskyy,
    socials: [
    ]
  },
  {
    name: 'classicj',
    title: 'strategy architect',
    image: cj,
    socials: [
    ]
  },
  {
    name: 'dudendy',
    title: 'warsmith',
    image: dudendy,
    socials: [
    ]
  },
  {
    name: 'soloegoist',
    title: 'hedge master',
    image: soloegoist,
    socials: [
    ]
  },
  {
    name: 'airmangataos',
    title: 'vanguard',
    image: airmangataos,
    socials: [
    ]
  },
  {
    name: 'slowlanelife',
    title: 'frontier explorer',
    image: slowlanelife,
    socials: [
    ]
  },
  {
    name: 'dsi',
    title: 'godspeed cadet',
    image: dsi,
    socials: [
    ]
  },
  {
    name: '9stx6',
    title: 'shadow ninja',
    image: x9stx6,
    socials: [
    ]
  },
  {
    name: 'sebax11',
    title: 'bounty hunter',
    image: sebax11,
    socials: [
    ]
  },
  {
    name: 'obivan',
    title: 'bounty hunter',
    image: obivan,
    socials: [
    ]
  },
  {
    name: 'dragon slayer ai',
    title: 'sentient',
    image: dragonslayer,
    socials: [
    ]
  }
];
